import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import urls from 'constants/urls';
import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { downloadFile } from 'helpers/file';
import { deleteActivationOrderDataType } from 'queries/activationOrder/deleteActivationOrder';
import useAlert from 'hooks/api/useAlert';
import useActivationOrder, { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import pencilSvg from 'assets/img/newPencil.svg';
import downloadSvg from 'assets/img/newDownload.svg';
import delSvg from 'assets/img/newDelete.svg';
import useErrorCallback from 'hooks/useErrorCallback';
import reloadSvg from 'assets/img/reload.svg';
import ChangeStatusModal from 'components/modal/ChangeStatusModal';
import { AOStatus, CompanyStatus } from 'components/common/Status';
import { changeStatusAO } from 'api/ao';
import { getCompanyById } from 'api/company';

type PropsT = {
  type: AoTypeEnum;
  id: activationOrderDataType['id'];
  deleteActivationOrder: (data: deleteActivationOrderDataType) => void;
  title: string;
  companyId?: string;
  setStatus?: any;
};

const ActionsButton: FC<PropsT> = ({ id, deleteActivationOrder, type, title, companyId, setStatus }) => {
  const { setAlert } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const { getAlert } = useErrorCallback();
  const history = useHistory();
  const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false);
  const { _, activationOrderData } = useActivationOrder();
  //@ts-ignore
  const { activationOrderId } = useParams();
  const [tax, setTax] = useState();

  useEffect(() => {
    if (companyId) getCompanyById(companyId).then((company: any) => setTax(company?.tax_treaty));
  }, []);

  return (
    <div style={{ position: 'absolute', top: -83, right: 0 }}>
      <ViewGeneralActions
        buttonList={[
          {
            image: pencilSvg,
            title: 'forms.activationOrder.editAo',
            style: { color: '#838998' },
            onClick: () => history.push(`${urls.getActivationOrder()}/edit/${type}/${id}`),
          },
          {
            image: reloadSvg,
            title: 'company.changeStatus',
            style: { color: '#838998' },
            onClick: () => setIsOpenChangeStatusModal(true),
          },
          {
            image: delSvg,
            title: 'forms.activationOrder.deleteAo',
            style: { color: '#EC3E72' },
            onClick: () => setIsDeleted(true),
          },
        ]}
      />
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title={title}
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          deleteActivationOrder({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id,
            successCallback: () => {
              history.push(urls.getActivationOrder());
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errorCallback: (error) => {
              setAlert({
                type: 'error',
                message: error.body.description,
              });
            },
          });
        }}
      />
      {isOpenChangeStatusModal && (
        <ChangeStatusModal
          tax={tax}
          isCompany={false}
          currentStatus={activationOrderData.status}
          isOpen={isOpenChangeStatusModal}
          onClose={() => setIsOpenChangeStatusModal(false)}
          handleConfirmClick={(status) => {
            setIsOpenChangeStatusModal(false);
            // @ts-ignore
            changeStatusAO(activationOrderId, status as AOStatus)
              .then((result) => setStatus(result))
              .catch(console.error);
          }}
        />
      )}
    </div>
  );
};

export default ActionsButton;
