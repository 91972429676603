// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlertMaterial from '@material-ui/lab/Alert';

import useAlert from 'hooks/api/useAlert';

const Alert: FC = () => {
  const classes = useStyles();
  const { alertData, setAlert } = useAlert();
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const getInitialState = useCallback(() => {
    setAlertType('');
    setAlertMessage('');
    setAlert({ type: '', message: '' });
    document.removeEventListener('click', getInitialState, true);
  }, [setAlert]);

  useEffect(() => {
    let timer;

    if (alertData?.message) {
      setAlertType(alertData.type);
      setAlertMessage(alertData.message);
      timer = setTimeout(() => {
        getInitialState();
        clearTimeout(timer);
      }, 10000);
      document.addEventListener('click', getInitialState, true);
    }
  }, [alertData]);

  if (!alertMessage) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AlertMaterial severity={alertType === 'error' ? 'error' : 'success'} icon={false} className={classes[alertType]}>
        {alertMessage}
      </AlertMaterial>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    alignItems: 'center',
    minWidth: '288px',
    justifyContent: 'center',
    left: '40px',
    bottom: '24px',
    color: '#fff',
    borderRadius: '8px',
    // transform: 'translateX(-50%)',
    // height: '56px',
    zIndex: 1500,
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    // },
    // '@media only screen and (max-width: 600px)': {
    //   width: '100%',
    // },
  },
  error: {
    backgroundColor: '#e57373',
    color: '#fff',
  },
  success: {
    backgroundColor: '#4E5169',
    color: '#fff',
  },
}));

export default Alert;
